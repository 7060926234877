
import { bootstrapApplication } from '@angular/platform-browser';
import { appConfig } from './app/app.config';
import { AppComponent } from './app/app.component';
import { Config } from '../../shared/src/lib/configs/index.config';
import { Prototypes } from 'ets-fe-ng-sdk';
import '../../shared/src/lib/prototypes/prototypes';
import Quill from 'quill';
import ImageResize from 'quill-image-resize-module';
import { environment } from '../../shared/src/environments/environment';
import { ESystem } from '../../shared/src/lib/models/index.model';
// Quill.register('modules/imageResize', ImageResize);

// debugger;
bootstrapApplication(AppComponent, appConfig).catch((err) => console.error(err));
