import { Injectable, inject } from '@angular/core';
import { catchError, exhaustMap, map, of, tap } from 'rxjs';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { UsersService } from '../../../../../admin/src/app/dashboard/set-ups/users/users.service';
import { UserStore } from './user.reducer';
import { BaseEffect } from '../base.effect';
import { environment } from '../../../environments/environment';

@Injectable()
export class UserEffect extends BaseEffect {
  protected service = inject(UsersService);

  fetchAll = createEffect(() =>
    this.actions$.pipe(
      ofType(UserStore.actions.API.fetchAll),
      exhaustMap((action) =>
        this.service.fetchAll({ company: environment.userCompanyCode }).pipe(
          map((r) => UserStore.actions.API.fetchAllSuccess({ result: r.content })),
          catchError((error) => of(UserStore.actions.API.fetchAllError({ error }))),
        ),
      ),
    ),
  );

  fetchAllReset = createEffect(() =>
    this.actions$.pipe(
      ofType(UserStore.actions.API.fetchAllReset),
      exhaustMap((action) =>
        this.service.fetchAll().pipe(
          map((r) => UserStore.actions.API.fetchAllSuccess({ result: r.content })),
          catchError((error) => of(UserStore.actions.API.fetchAllError({ error }))),
        ),
      ),
    ),
  );

  fetchSingle = createEffect(() =>
    this.actions$.pipe(
      ofType(UserStore.actions.API.fetchSingle),
      exhaustMap((action) =>
        this.service.fetchAll({ userName: action.username }).pipe(
          map((r) => UserStore.actions.API.fetchSingleSuccess({ result: r.content?.[0] })),
          catchError((error) => of(UserStore.actions.API.fetchSingleError({ error }))),
        ),
      ),
    ),
  );

  createSuccess = this.actions$.pipe(
    ofType(UserStore.actions.API.createSuccess),
    tap(() => this.store.dispatch(UserStore.actions.API.fetchAllReset())),
  );
  createError = this.actions$.pipe(ofType(UserStore.actions.API.createError));
  updateSuccess = this.actions$.pipe(
    ofType(UserStore.actions.API.updateSuccess),
    tap(() => this.store.dispatch(UserStore.actions.API.fetchAllReset())),
  );
  updateError = this.actions$.pipe(ofType(UserStore.actions.API.updateError));

  create = createEffect(() =>
    this.actions$.pipe(
      ofType(UserStore.actions.API.create),
      exhaustMap((action) =>
        this.service.create(action.item).pipe(
          map((r) =>
            UserStore.actions.API.createSuccess({
              result: r,
            }),
          ),
          catchError((error) => of(UserStore.actions.API.createError({ error }))),
        ),
      ),
    ),
  );

  update = createEffect(() =>
    this.actions$.pipe(
      ofType(UserStore.actions.API.update),
      exhaustMap((action) =>
        this.service.update(action.item).pipe(
          map((r) =>
            UserStore.actions.API.updateSuccess({
              result: r,
            }),
          ),
          catchError((error) => of(UserStore.actions.API.updateError({ error }))),
        ),
      ),
    ),
  );
}
