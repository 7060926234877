import { ApplicationConfig } from '@angular/core';

import { routes } from './app.routes';
import { sharedAppConfig } from '../../../shared/src/lib/shared-app.config';
import { environment } from '../../../shared/src/environments/environment';
import { ESystem } from '../../../shared/src/lib/models/index.model';

function setVariables() {
  environment.appName = 'Candidate Portal';
  // environment.allowRegistration = true;
  environment.system = ESystem.candidate;
  environment.useRemita = true; 
  environment.sentryKey =
    'https://65b81ac44c139c845c0a5a394077db49@o4507594374250496.ingest.de.sentry.io/4507599271886928';
  return environment;
}
export const appConfig: ApplicationConfig = sharedAppConfig(routes, setVariables());
