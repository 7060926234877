import { Routes } from '@angular/router';

export const routes: Routes = [
  { path: '', redirectTo: 'home', pathMatch: 'full' },
  {
    path: '',
    loadComponent: () =>
      import('../../../shared/src/lib/components/layouts/horizontal-layout/horizontal-layout.component').then(
        (c) => c.HorizontalLayoutComponent,
      ),
    children: [
      {
        path: 'home',
        loadComponent: () => import('./cp-home/cp-home.component').then((c) => c.CpHomeComponent),
      },
      {
        path: 'candidate/edit',
        data: {
          title: 'Update Application',
        },
        loadComponent: () =>
          import(
            '../../../admin/src/app/dashboard/intermediary-desk/cifm/candidates/save-candidate/save-candidate.component'
          ).then((c) => c.SaveCandidateComponent),
      },
      // {
      //   path: 'agent/edit',
      //   data: {
      //     title: 'Update Agent Information',
      //   },
      //   loadComponent: () =>
      //     import(
      //       '../../../admin/src/app/dashboard/admin/agent/save-legacy-agent/save-legacy-agent.component'
      //     ).then((c) => c.SaveLegacyAgentComponent),
      // },
      {
        path: 'employer/update',
        data: {
          title: 'Update Employer',
        },
        loadComponent: () =>
          import('./cp-employer-update/cp-employer-update.component').then(
            (c) => c.CpEmployerUpdateComponent,
          ),
      },
      {
        path: 'exam',
        loadChildren: () => import('./candidate-exam/candidate-exam.routes'),
      },
      {
        path: 'courses',
        loadChildren: () => import('./cp-courses/cp-courses.routes'),
      },
      {
        path: 'profile',
        data: {
          title: 'Profile',
        },
        loadComponent: () => import('./cp-profile/cp-profile.component'),
      },
    ],
  },
];
